import classnames from 'classnames';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import styles from './App.module.sass';
import useSync from './hooks/use-sync';

import Header from './components/header/header';
import MainView from './components/main-view/main-view';
import { useCookies } from 'react-cookie';

import Login from './components/auth/login';
import { useAppActions, useAppState } from './store/hooks/use-app';
import { useBubblesActions, useBubblesState } from './store/hooks/use-bubbles';
import Invite from './components/invite/invite';
import Home from './components/home/home';
import Subscription from './components/subscription/subscription';
import Welcome from './components/welcome/welcome';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const { loadUserData } = useAppActions();
  const { loadingUser, loggedIn } = useAppState();
  const { loading } = useBubblesState();
  const { setLoading } = useBubblesActions();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['regCode']);
  const [regCode, setRegCode] = useState(() => {
    return cookies.regCode ?? "";
  });

  useEffect(() => {
    setLoading(false);
  }, []);



  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(()=>{
    if(regCode != ""){
      setCookie('regCode', "", { path: '/' });
      setRegCode("")
      navigate("/welcome")
    }
  }, [])

  // initialize sync handler
  useSync();

  if (loadingUser) {
    return <div>Loading...</div>;
  }

  if (!loggedIn) {
    return <Login />;
  }

  return (
    <div className={classnames(styles.app, { [styles.loading]: loading })}>
      <Header />
      <Routes>
        <Route path="/:bubbleId" element={<MainView />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/home" element={<Home />} />
        <Route path="/welcome" element={<Welcome/>} />
        <Route path="/" element={<MainView />} />
      </Routes>
    </div>
  );
};

export default App;
