/**
 * Utility function to get the cursor position relative to a DOM element.
 * @param {Object} event - The event object (e.g., drop, mouse event).
 * @param {HTMLElement} canvasElement - The DOM element to calculate the position relative to.
 * @returns {{x: number, y: number}} Position object with x and y coordinates.
 */
export const getCursorPosition = (event, canvasElement) => {
    if (!event || typeof event.clientX !== 'number' || typeof event.clientY !== 'number') {
      console.warn('Invalid event object provided to getCursorPosition.');
      return { x: 0, y: 0 };
    }
  
    if (!canvasElement || !(canvasElement instanceof HTMLElement)) {
      console.warn('Invalid canvasElement provided to getCursorPosition.');
      return { x: 0, y: 0 };
    }
  
    return {
      x: Math.max(0, event.clientX - canvasElement.offsetLeft),
      y: Math.max(0, event.clientY - canvasElement.offsetTop),
    };
  };
  