import React from 'react';
import styles from './loader.module.sass';

const Loader = ({ message, progress }) => {
    console.log(progress);
  return (
    <div className={styles.loader}>
      <div className={styles.spinner}></div>
      {message && <p>{message}</p>}
      {typeof progress === 'number' && (
        <div className={styles.progressContainer}>
          <div
            className={styles.progressBar}
            style={{ width: `${progress}%` }}
          ></div>
          <div className={styles.progressText}>{progress}%</div>
        </div>
      )}
    </div>
  );
};

export default Loader;
