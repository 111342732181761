import { updateLastViewedBubbles, getLastViewedBubbles } from "../../../api";

export function getBubbleName(bubble) {
    let res = "";

    // Helper function to recursively traverse the object
    function traverse(item) {
        if (typeof item === 'object' && item !== null) {
            // If the item is an object or array, iterate through its keys or elements
            for (let key in item) {
                if (key === 'text') {
                    // If the key is 'text', push its value to textValues
                    res += item[key];
                }
                // Recursively traverse the value
                traverse(item[key]);
            }
        }
    }
    try {
        // Ensure bubble.name is a valid JSON string before parsing
        const nameData = bubble?.name ? JSON.parse(bubble.name) : null;
        traverse(nameData);
    } catch (error) {
        console.error("Error parsing bubble name:", error);
    }

    return res;
}

export async function setBubbleHistory(canvasParentId, bubbleText) {
    const lastViewedBubbles = await getLastViewedBubbles();
  
    let canvasIds = lastViewedBubbles.bubbleId || [];
    let bubbleTexts = lastViewedBubbles.bubbleText || [];
    let updatedAts = lastViewedBubbles.updatedAt ||
    
    [];
    let updatedAt = Date.now();
  
  
    const index = canvasIds.indexOf(canvasParentId);
  
    if (index >= 0) {
      canvasIds.splice(index, 1);
      const oldText = bubbleTexts[index];
      bubbleTexts.splice(index, 1);
      updatedAts.splice(index, 1);
  
      canvasIds.unshift(canvasParentId);
      bubbleTexts.unshift(bubbleText ?? oldText); 
      updatedAts.unshift(updatedAt);
    } else {
      canvasIds.unshift(canvasParentId);
      bubbleTexts.unshift(bubbleText ?? ""); 
      updatedAts.unshift(updatedAt);
    }
  
    if (canvasIds.length > 10 || bubbleTexts.length > 10 || updatedAts.length > 10) {
      canvasIds = canvasIds.slice(0, 10);
      bubbleTexts = bubbleTexts.slice(0, 10);
      updatedAts = updatedAts.slice(0, 10);
    }
  
    updateLastViewedBubbles(canvasIds, bubbleTexts, updatedAts);
  }
  