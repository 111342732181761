import { push, goBack } from 'redux-first-history';
import {
  handleImageGeneration,
  handleTextGeneration,
  loadBubbles,
  deleteBubble,
  signOutUser,
  syncBubbles,
  savePrompt,
  fetchLatestPrompts,
  actions,
  getBubbleName,
  getInitialBubbleId,
} from '../bubbles.slice';
import { useSelector, useDispatch } from 'react-redux';

export const useBubblesState = () => {
  const {
    bubbles,
    focusedBubbleId,
    selectedBubbles,
    loading,
    latestPrompts,
    loadingLatestPrompts,
    latestPromptsError,
    uploadProgress
  } = useSelector((state) => state.bubbleData);

  const focusedBubble = bubbles?.find?.(
    (bubble) => bubble.id === focusedBubbleId,
  );

  return {
    bubbles,
    focusedBubble,
    focusedBubbleId,
    selectedBubbles,
    loading,
    latestPrompts,
    loadingLatestPrompts,
    latestPromptsError,
    uploadProgress
  };
};

export const useBubblesActions = () => {
  const dispatch = useDispatch();
  const {
    bubbles,
    focusedBubbleId,
    selectedBubbles,
    focusedBubble,
    contextHistory,
  } = useSelector((state) => state.bubbleData);

  return {
    setLoading: (loading) => dispatch(actions.setLoading(loading)),
    fetchLatestPrompts: () => {
      dispatch(fetchLatestPrompts());
    },
    addBubble: (properties) => dispatch(actions.addBubble({ properties })),
    getBubbleName: ({id}) => dispatch(getBubbleName({id})),
    addCanvasBubble: ({ id, properties, location, dimensions }) =>
      dispatch(actions.addCanvasBubble({ id, properties, location, dimensions })),
    addDocumentBubble: ({
      id,
      parentBubbleId,
      properties,
      previousSibling,
      canvasParentId,
      parentCanvasIndex,
    } = {}) =>
      dispatch(
        actions.addDocumentBubble({
          id,
          parentBubbleId,
          properties,
          previousSibling,
          canvasParentId,
          parentCanvasIndex,
        }),
      ),
    duplicateSelectedBubbles: () => {
      dispatch(actions.duplicateSelectedBubbles());
    },
    handleImageGeneration: ({ promptText }) =>
      dispatch(handleImageGeneration({ promptText })),
    handleTextGeneration: ({ promptText, content, parentId }) =>
      dispatch(handleTextGeneration({ promptText, content, parentId })),
    savePrompt: ({ promptText }) => dispatch(savePrompt({ promptText })),
    markSelectedBubbles: (selectionRectangle) =>
      dispatch(actions.markSelectedBubbles(selectionRectangle)),
    moveDocumentBubble: ({
      id,
      parentBubbleId,
      canvasParentId,
      parentCanvasIndex,
      newIndex,
      oldIndex,
      shouldUpdateCountChange
    }) =>
      dispatch(
        actions.moveDocumentBubble({
          id,
          parentBubbleId,
          canvasParentId,
          parentCanvasIndex,
          newIndex,
          oldIndex,
          shouldUpdateCountChange,
        }),
      ),
    moveCanvasBubble: ({ id, canvasIndex, delta }) =>
      dispatch(actions.moveCanvasBubble({ id, canvasIndex, delta })),
    moveCanvasChildren: ({
      canvasIndices,
      fromBubbleId,
      toBubbleId,
      location,
    }) =>
      dispatch(
        actions.moveCanvasChildren({
          canvasIndices,
          fromBubbleId,
          toBubbleId,
          location,
        }),
      ),
    notifyCanvasBubbles: ({ id, canvasParentId, canvasIndex }) =>
      dispatch(
        actions.notifyCanvasBubbles({ id, canvasParentId, canvasIndex }),
      ),
    removeCanvasBubbles: ({ parentBubbleId, indices }) =>
      dispatch(
        actions.removeCanvasBubbles({
          parentBubbleId,
          indices,
        }),
      ),
    removeDocumentBubbles: ({ parentBubbleId, ids }) =>
      dispatch(
        actions.removeDocumentBubbles({
          parentBubbleId,
          ids,
        }),
      ),
    removeSelectedCanvasBubbles: () =>
      dispatch(actions.removeSelectedCanvasBubbles()),
    deleteBubble: async () => {
      const focusBubble = bubbles.find((b) => b.id === focusedBubbleId);
      let selectedBubble = focusBubble.canvasChildren.reduce(
        (result, b, index) => {
          if (selectedBubbles.includes(index)) {
            result.push({ id: b.id, index });
          }
          return result;
        },
        [],
      );
      try {
        await dispatch(deleteBubble(selectedBubble));
        dispatch(actions.deleteBubble(selectedBubble));
      } catch (error) {
        console.error('Error deleting bubble:', error);
      }
    },
    newCanvas: () => {
      const newBubbleId = getInitialBubbleId();
      dispatch(push('/' + newBubbleId));
      dispatch(actions.newCanvas({ newBubbleId }));
    },
    newDocument: () => {
      const newBubbleId = getInitialBubbleId();
      dispatch(push('/' + newBubbleId));
      dispatch(actions.newDocument({ newBubbleId }));
    },
    setViewMode: (viewMode) => dispatch(actions.setViewMode({ viewMode })),
    stepIn: ({ id }) => {
      dispatch(push('/' + id));
      dispatch(actions.stepIn({ id }));
    },
    syncToUrl: ({ idInPath }) => {
      dispatch(actions.stepIn({ id: idInPath }));
    },
    stepOut: () => {
      // const lastBubbleId = contextHistory[contextHistory.length - 1] ?? '';
      // dispatch(push('/' + lastBubbleId));
      dispatch(goBack()); // Mitä stepoutin pitäisi tehdä?
      dispatch(actions.stepOut());
    },
    toggleExpanded: ({ id }) => dispatch(actions.toggleExpanded({ id })),
    updateCanvasBubbleProperties: ({
      canvasIndex,
      canvasParentId,
      canvasProperties,
    }) =>
      dispatch(
        actions.updateCanvasBubbleProperties({
          canvasIndex,
          canvasParentId,
          canvasProperties,
        }),
      ),
    loadBubbles: ({ newFocusedBubbleId, isInitialLoad } = {}) =>
      dispatch(loadBubbles({ newFocusedBubbleId, isInitialLoad })),
    signOutUser: () => dispatch(signOutUser()),
    syncBubbles: () => dispatch(syncBubbles()),
    updateBubble: ({ id, properties }) =>
      dispatch(actions.updateBubble({ id, properties })),
    updateDocumentBubble: ({
      id,
      canvasParentId,
      parentCanvasIndex,
      properties,
    }) =>
      dispatch(
        actions.updateDocumentBubble({
          id,
          canvasParentId,
          parentCanvasIndex,
          properties,
        }),
      ),
  };
};

export default {
  useBubblesState,
  useBubblesActions,
};
