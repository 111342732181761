import React, { useEffect, useState } from 'react';
import Header from '../header/header';
import styles from './home.module.sass';
import { useAppState } from '../../store/hooks/use-app';
import BackgroundGenerator from '../common/background-generator';
import Button from '../common/button';
import { MonitorPlay, Info, Notebook, PictureInPicture2, Sparkles } from "lucide-react";
import { useBubblesActions, useBubblesState, } from '../../store/hooks/use-bubbles';
import { getBubbleName, setBubbleHistory } from '../main-view/document/document-func';
import { getLastViewedBubbles } from '../../api';
const Home = () => {
  const { stepIn } = useBubblesActions();
  const [lastViewedBubbles, setLastViewedBubbles] = useState([]);
  const hasBubbles = true;

  useEffect(() => {
    const fetchLastViewedBubbles = async () => {
      try {
        const data = await getLastViewedBubbles();
        setLastViewedBubbles(data?.bubbleId.map((id, index) => ({
          id,
          text: data.bubbleText[index],
          updatedAt: data.updatedAt[index],
        })) || []);
      } catch (error) {
        console.error('Error fetching last viewed bubbles:', error);
      }
    };

    fetchLastViewedBubbles();
  }, []);

  const onStepIn = (id) => {
    
    setBubbleHistory(id);

    stepIn({ id });
  };

  const getTimeAgo = (updatedAt) => {
    const now = new Date();
    const updated = new Date(updatedAt || -1);
    const diffInSeconds = Math.floor((now - updated) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minute${Math.floor(diffInSeconds / 60) > 1 ? 's' : ''} ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hour${Math.floor(diffInSeconds / 3600) > 1 ? 's' : ''} ago`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} day${Math.floor(diffInSeconds / 86400) > 1 ? 's' : ''} ago`;
    if (diffInSeconds < 2592000) return `${Math.floor(diffInSeconds / 604800)} week${Math.floor(diffInSeconds / 604800) > 1 ? 's' : ''} ago`;
    if (diffInSeconds < 31536000) return `${Math.floor(diffInSeconds / 2592000)} month${Math.floor(diffInSeconds / 2592000) > 1 ? 's' : ''} ago`;
    return `${Math.floor(diffInSeconds / 31536000)} year${Math.floor(diffInSeconds / 31536000) > 1 ? 's' : ''} ago`;
  };


  return (
    <>
      <Header />
      {/* <BackgroundGenerator /> */}
      <div className={styles.home}>
        <div className={styles.quickLinks}>
        <div className={styles.module}>
            <h3>Last Viewed Bubbles</h3>
            {lastViewedBubbles.length > 0 ? (
              <div className={styles.bubblesContainer}>
                {lastViewedBubbles.map((bubble) => (
                  <div
                    key={bubble.id}
                    className={styles.bubbles}
                    onClick={() => onStepIn(bubble.id)}
                  >
                    <div className={styles.bubble}>
                      <div className={styles.bubbleHeader}>
                        {bubble.text?.length > 0 ? bubble.text : 'Unnamed bubble'}
                      </div>
                      <div className={styles.bubbleContent}></div>
                      <div className={styles.bubbleUpdatedAt}>
                        <small>{getTimeAgo(bubble.updatedAt)}</small>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.info}>You'll see your latest Bubbles here.</div>
            )}
          </div>
           {/* <div className={`${styles.module} ${styles.keyTopics}`}>
            <h3>Key topics</h3>

            {hasBubbles ? (
              <div className={styles.topics}>
                <div className={styles.topic}>
                  <div className={styles.topicName}>Information theory</div>
                </div>
                <div className={styles.topic}>
                  <div className={styles.topicName}>Design</div>
                </div>
                <div className={styles.topic}>
                  <div className={styles.topicName}>#bubbles</div>
                </div>
                <div className={styles.topic}>
                  <div className={styles.topicName}>Brains</div>
                </div>
              </div>
            ) : (
              <div className={styles.info}>
                You'll see your key topics here once there are at least 10 Bubbles with content.
              </div>
            )}
          </div> */}
        </div>
      </div>
      
    </>
  );
};

export default Home;