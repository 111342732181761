import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../config';
import styles from './login.module.sass';
import { validateRegistartionCode } from '../../api';
import { useCookies } from 'react-cookie';
import { redirect } from 'react-router-dom';
import Button from '../common/button';
import { Ban } from "lucide-react";


const LoginScreen = () => {
    const [circles, setCircles] = useState([]);
    const [backgroundStyle, setBackgroundStyle] = useState({});
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
      const [cookies, setCookie] = useCookies(['regCode']);
      const [regCode, setRegCode] = useState(() => {
        return cookies.regCode ?? "";
      });

    useEffect(() => {
        generateDesign();
        const handleKeyDown = (event) => {
            if (event.key === ' ') {
                generateDesign();
                event.preventDefault();
            }
        };
        const handleKeyUp = (event) => {

        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const generateDesign = () => {
        const gradientAngle = Math.floor(Math.random() * 360);
        const color1lightness = Math.floor(Math.random() * 100);
        const color2lightness = Math.floor(Math.random() * 100);

        const color1 = `hsl(${Math.floor(Math.random() * 360)}, ${Math.floor(Math.random() * 100)}%, ${color1lightness}%)`;
        const color2 = `hsl(${Math.floor(Math.random() * 360)}, ${Math.floor(Math.random() * 100)}%, ${color2lightness}%)`;

        const averageLightness = (color1lightness + color2lightness) / 2;

        const numCircles = Math.floor(Math.random() * 3) + 1;
        const newCircles = Array(numCircles).fill().map((_, i) => ({
            id: i,
            width: Math.floor(Math.random() * 500) + 200,
            top: Math.floor(Math.random() * 600) - 150,
            left: Math.floor(Math.random() * 520) - 105,
            filter: averageLightness > 75 ? 'invert(1)' : 'none',
        }));    

        setBackgroundStyle({
          '--color1': color1,
          '--color2': color2,
          '--gradient-angle': `${gradientAngle}deg`,
        });

        setCircles(newCircles);
    };

    const validateCode = () =>{
        console.log(code, "éfkhrdkjghdkljfhgldtkh")
        setCookie('regCode', code, { path: '/' });
        setRegCode(code)
        validateRegistartionCode().then(j=>{
           window.location.href = `${BASE_URL}/auth/login/federated/google`;
        }).catch(e=>{
            setError(e.response.data.error)
        })
    }

    return (
        <div className={styles.loginScreen}>
            <div className={styles.background}>
                <div className={styles.design} style={backgroundStyle}>
                    {circles.map(circle => (
                        <div
                            key={circle.id}
                            className={styles.circle}
                            style={{
                                width: circle.width,
                                height: circle.width,
                                top: circle.top,
                                left: circle.left,
                                filter: circle.filter,
                            }}
                        ></div>
                    ))}
                </div>
            </div>
            <div className={styles.login}>
                <div className={styles.loginContent}>
                    <h1>Welcome to Bubbles</h1>
                    <h3>From scattered thoughts to structured brilliance</h3>
                    <Button
                        icon={<img src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA" alt="" srcset="" />}
                        label="Log in with Google"
                        onClick={()=>window.location.href = `${BASE_URL}/auth/login/federated/google`}
                    />

                    <h4>New user? Enter your access code:</h4>
                    <div className={styles.registrationContainer}>
                        <input type="text" placeholder='Enter access code here' value={code} onChange={e=>setCode(e.target.value)}/>
                        <Button label="Continue" onClick={validateCode} />
                    </div>
                    {error.length > 0 ? <div className={styles.errorContainer}>
                        <div className={styles.errorIcon}>  
                            <Ban size={16} />
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: error }}></p>
                        </div> : null}
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
