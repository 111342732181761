import {
  useBubblesActions,
  useBubblesState,
} from '../../store/hooks/use-bubbles';

//import { getNodeFromPlainText } from './document/prosemirror-utils';

import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/bubbles.slice';
import api from '../../api';
import { useState } from 'react';
import { AllowedFileTypes, getCursorPosition } from '../../utils';

const COPIED_BUBBLES_TYPE = 'copied-bubbles';
const COPY_KEY = 'c';
const PASTE_KEY = 'v';
const CUT_KEY = 'x';

const allowedFileTypes = Object.values(AllowedFileTypes)
const checkCombination = (e, key) => {
  const charCode = String.fromCharCode(e.keyCode).toLowerCase();

  return (e.metaKey || e.ctrlKey) && charCode === key;
};

const useClipboard = () => {
  const dispatch = useDispatch();
  const { selectedBubbles, focusedBubble, focusedBubbleId } = useBubblesState();
  const { addCanvasBubble, addDocumentBubble, removeSelectedCanvasBubbles } =
    useBubblesActions();
    const [isDraggingOverTarget, setIsDraggingOverTarget] = useState(false);
    const [isUnsupportedFile, setIsUnsupportedFile] = useState(false);
    

  const copySelectedBubbles = ({ cut = false } = {}) => {
    const clipboardContent = JSON.stringify({
      type: COPIED_BUBBLES_TYPE,
      sourceCanvas: focusedBubbleId,
      copiedBubbles: selectedBubbles.map(
        (index) => focusedBubble.canvasChildren[index],
      ),
    });

    const onSuccess = () => {
      if (cut) {
        removeSelectedCanvasBubbles();
      }
    };

    const onFailure = (e) => console.error('Failed to copy: ', e);

    navigator.clipboard.writeText(clipboardContent).then(onSuccess, onFailure);
  };

  const handleCopyPaste = async (e) => {
    if(!(e?.target?.classList?.contains('text-input'))){
      if (checkCombination(e, COPY_KEY)) {
        handleCopy();
      }

      if (checkCombination(e, PASTE_KEY)) {
        handlePaste(e);
      }

      if (checkCombination(e, CUT_KEY)) {
        handleCut();
      }
    }
  };

  const handleCopy = () => {
    if (selectedBubbles?.length) {
      copySelectedBubbles();
    }
  };

  const handlePaste = async (e) => {
    navigator.clipboard.readText().then(async (clipText) => {
      try {
        const clipboardContent = JSON.parse(clipText);
        if (clipboardContent?.type === COPIED_BUBBLES_TYPE) {
          e.stopPropagation();
          handleParsedBubblesPaste(clipboardContent);
        }
      } catch (err) {
        handleNonJsonPaste(e, clipText);
      }
    });
  };

  const handleParsedBubblesPaste = (clipboardContent) => {
    const { sourceCanvas, copiedBubbles } = clipboardContent;
    const locationModifier =
      sourceCanvas === focusedBubbleId ? { x: 50, y: 50 } : { x: 0, y: 0 };
    copiedBubbles.forEach(({ id, location, dimensions }) => {
      addCanvasBubble({
        id,
        location: {
          x: location.x + locationModifier.x,
          y: location.y + locationModifier.y,
        },
        dimensions,
      });
    });
  };

  const handleNonJsonPaste = async (e, clipText) => {
    if (typeof clipText === 'string' && clipText.length > 0) {
      e.stopPropagation();
      handleTextPaste(clipText);
    } else {
      handleNonTextPaste();
    }
  };

  const handleTextPaste = (clipText) => {
    const canvasBubbleId = uuidv4();
    addCanvasBubble({ id: canvasBubbleId });
    const docBubbleId = uuidv4();
    const bubbleName = JSON.stringify({id: docBubbleId, type: 'paragraph', content:[{type: 'text', text: clipText}]});
    addDocumentBubble({
      parentBubbleId: canvasBubbleId,
      properties: { name: bubbleName, isAutoResizeOnCreate: true },
    });
  };

  const handleNonTextPaste = async () => {
    const clipboardItems = await navigator.clipboard.read();
    for (const item of clipboardItems) {
      if (
        item.types.includes('image/png') ||
        item.types.includes('image/jpeg')
      ) {
        handleImagePaste(item);
      }
    }
  };

  const handleImagePaste = async (item) => {
    const blob =
      (await item.getType('image/png')) || (await item.getType('image/jpeg'));
    const reader = new FileReader();
    reader.onload = () => {
      api.handleImageUpload({imageContent: reader.result}).then((res)=>{
        dispatch(
          actions.addCanvasBubble({
            properties: {
              imageContent: res.url,
              viewMode: 'document',
            },
          }),
        );
      });

    };
    reader.readAsDataURL(blob);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDraggingOverTarget(false);
    
    const { x: canvasX, y: canvasY } = getCursorPosition(event, event.target);
    const items = event.dataTransfer.items;
  
    for (const item of items) {
     if (item.kind === 'file' && allowedFileTypes.includes(item.type)) {
        const file = item.getAsFile();
        if (file) {
          await handleImageDrop(file, { x: canvasX, y: canvasY });
        }
      }
    }
  };
  
  
  const handleImageDrop = async (file, location) => {
    try {
      dispatch(actions.setLoading(true)); 
      dispatch(actions.setUploadProgress(0)); 
  
      const reader = new FileReader();
  
      reader.onloadstart = () => {
        dispatch(actions.setUploadProgress(10)); 
      };
  
      reader.onload = async () => {
        dispatch(actions.setUploadProgress(40)); 
  
        const imageContent = reader.result;
  
       
          const res = await api.handleImageUpload({ imageContent });
  
          dispatch(actions.setUploadProgress(100)); // Upload complete
          
          setTimeout(() => {
            dispatch(
              actions.addCanvasBubble({
                properties: {
                  imageContent: res.url,
                  viewMode: 'document',
                },
                location,
              })
            );
            dispatch(actions.setLoading(false)); 
          }, 300); 
         
      };
  
      reader.readAsDataURL(file); 
    } catch (error) {
      console.error("Failed to handle image drop:", error);
      dispatch(actions.setLoading(false)); 
    }
  }; 

  
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDraggingOverTarget(true);

    const items = event.dataTransfer.items; 
    const hasUnsupportedFile = Array.from(items).some(
      (item) => item.kind === "file" && !allowedFileTypes.includes(item.type)
    );

    setIsUnsupportedFile(hasUnsupportedFile)
    event.dataTransfer.dropEffect = hasUnsupportedFile ? "none" : "copy";
  };

  const handleDragLeave = () => {
    setIsDraggingOverTarget(false);
    setIsUnsupportedFile(false)
  };
  const handleCut = () => {
    if (selectedBubbles?.length) {
      copySelectedBubbles({ cut: true });
    }
  };

  return {
    handleCopyPaste,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    isDraggingOverTarget,
    isUnsupportedFile
  };
};

export default useClipboard;
