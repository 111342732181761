import React from 'react';
import Header from '../header/header';
import styles from './welcome.module.sass';
import { useAppState } from '../../store/hooks/use-app';
import BackgroundGenerator from '../common/background-generator';
import Button from '../common/button';
import { MonitorPlay, Info, Notebook, PictureInPicture2, Sparkles } from "lucide-react";
import {useBubblesActions,useBubblesState,} from '../../store/hooks/use-bubbles';
import { getBubbleName } from '../main-view/document/document-func';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const { user } = useAppState();
  const [isWelcomeHidden, setIsWelcomeHidden] = React.useState(false);
  const hasBubbles = true;
  const {
    stepIn,
  } = useBubblesActions();
  const navigate = useNavigate();


  return (
    <>
      <Header />
      {/* <BackgroundGenerator /> */}
      <div className={styles.home}>
        <div className={`${styles.welcome} ${isWelcomeHidden ? styles.hidden : ''}`}>
          <div className={styles.content}>
            <h1>Tervetuloa Bubblesiin!</h1>

            <p>Kuulut noin kolmenkymmenen ensimmäisen Bubblesin testikäyttäjän joukkoon - jännittävää!</p>

            <p><em>Bubblesin tarkoitus on sujuvoittaa ideoiden jäsentelyä ja työstämistä.</em></p>

            <ul>
              <li>Aloita klikkaamalla ylälaidan nappeja</li>
              <li>Canvas-näkymässä voit luoda uusia kuplia tuplaklikkaamalla</li>
              <li>Kuplia voi kopioida, liittää ja leikata tutuilla näppäinkomennoilla - myös kuvien liittäminen onnistuu.</li>
            </ul>

            <p><em>Kokeile ja klikkaile kaikkea, minkään ei pitäisi hajota pahasti!</em></p>

            <p>Kysymyksiä, palautetta ja ideoita voit lähettää minulle matalalla kynnyksellä <a href="https://x.com/tvirtanen_" target="_blank" rel="noreferrer">Twitterin</a> tai <a href="https://linkedin.com/in/timovirtanen1" target="_blank" rel="noreferrer">Linkedinin</a> kautta.</p>

            {/* <div className={styles.buttons}>
              <Button icon={<MonitorPlay />} primary label="Katso video" />
            </div> */}

            <div className={styles.creatorProfile}>
              <img src="https://timovirtanen.fi/bubbles-creator.jpg" alt="" />
              <p>
                Propellipäisin terveisin, <br />
                <span style={{ fontWeight: '600' }}>Timo Virtanen</span> <br />
                <a href="https://x.com/tvirtanen_" target="_blank" rel="noreferrer">Twitter / X</a>, &nbsp;
                <a href="https://linkedin.com/in/timovirtanen1" target="_blank" rel="noreferrer">LinkedIn</a>
              </p>
            </div>

          </div>
        </div>
      </div>
          {/* <div className={styles.module}>
            <h3>On this day</h3>
            <div className={styles.bubbles}>
              <div className={styles.bubble}>
                <div className={styles.bubbleHeader}>Bubble title</div>
                <div className={styles.bubbleContent}>
                  <p>Bubble content</p>
                </div>
              </div>
            </div>
          </div> */}
      {/* <div className={styles.randomBubble}> 
        <Button icon={<Sparkles />} primary label="Take me to a random Bubble" />
      </div> */}
    </>
  );
};

export default Welcome;