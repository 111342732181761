import classnames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import { ArrowUp } from 'lucide-react';
import { useCookies } from 'react-cookie';
import styles from './chat-canvas.module.sass';
import { ChatDocument } from './document/document';
import { v4 as uuidv4 } from 'uuid';
import { getBubbleName } from './document/document-func';
import Button from '../common/button';
import {
    useBubblesActions,
    useBubblesState,
} from '../../store/hooks/use-bubbles';
import { forEach, head } from 'lodash';

const ChatCanvas = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['conversationBubble']);
    const [scope, setScope] = useState('universal');
    const [activeChat, setActiveChat] = useState(false);
    const [showFullPromptModule, setShowFullPromptModule] = useState(false);
    const [conversationBubble, setConversationBubble] = useState(() => {
        return cookies.conversationBubble ? cookies.conversationBubble : undefined;
    });
    const [conversationTitle, setConversationTitle] = useState('');
    const { bubbles, focusedBubbleId, selectedBubbles } = useBubblesState();
    const { addBubble, addDocumentBubble, addCanvasBubble, handleTextGeneration, updateCanvasBubbleProperties } = useBubblesActions();
    const canvasRef = useRef();
    let input = useRef();
    let isOpen = useRef();
    isOpen.state = showFullPromptModule;


    
    if (!conversationBubble) {
        const id = uuidv4();
        addBubble({ id, viewMode: 'chat' });
        setConversationBubble(id);
        setCookie('conversationBubble', id, { path: '/', maxAge: 60 * 60 * 24 });
    }

    let bubble = bubbles.find((b) => b.id === conversationBubble);
    const canvasbubble = bubbles.find((b) => b.id === focusedBubbleId);

    if (!bubble) {
        const id = uuidv4();
        addBubble({ id, viewMode: 'chat' });
        setConversationBubble(id);
        setCookie('conversationBubble', id, { path: '/', maxAge: 60 * 60 * 24 });
        bubble = bubbles.find((b) => b.id === conversationBubble);
    }

    useEffect(()=>{
            setActiveChat(bubble.documentChildren.length > 1)
    }, [conversationBubble])

    const getSelectedContent = () => {
        const focusedBubble = bubbles.find((b) => b.id === focusedBubbleId);
        if (!focusedBubble) {
            console.warn("Focused bubble not found");
            return null;
        }

        const getContentAndChildren = (currentBubble) => {
            if (!currentBubble) {
                console.warn("Invalid bubble passed to getContentAndChildren");
                return null;
            }

            const children = currentBubble.expanded
                ? currentBubble.canvasChildren
                    ?.map(({ id }) => bubbles.find((b) => b.id === id))
                    .filter(Boolean)
                    .map(getContentAndChildren)
                : null;

            const docBubbs = currentBubble.documentChildren
                ?.map((m) => {
                    const childBubble = bubbles.find((b) => b.id === m);
                    return childBubble ? getBubbleName(childBubble) : "Unnamed Bubble";
                })
                .filter(Boolean);

            if (currentBubble.name) {
                docBubbs.push(getBubbleName(currentBubble));
            }

            return {
                text: docBubbs,
                children,
            };
        };

        const parents = selectedBubbles.map((index) => {
            const child = focusedBubble.canvasChildren[index];
            if (!child) {
                console.warn(`Invalid index ${index} in selectedBubbles`);
                return null;
            }
            return bubbles.find((b) => b.id === child.id);
        }).filter(Boolean);

        if (parents?.length) {
            return parents.map(getContentAndChildren);
        }

        return null;
    };

    let selectedContent = getSelectedContent();

    const chatMemory=(content = {})=>{

            content.history =  [];
            for (let i = 0; i < bubble.documentChildren.length; i++) {
                const chatBubble=bubbles.find((b) => b.id === bubble.documentChildren[i])
                const chatBubbleNameParsed=JSON.parse(chatBubble.name)
                if (chatBubbleNameParsed.props.backgroundColor == "hu-single") {
                   
                    const newEntry = {
                        role: "user",
                        content:getBubbleName(chatBubble) ,
                    };
                    content.history.push(newEntry);
                }
                else{
                    const newEntry = {
                        role: "assistant",
                        content:getBubbleName(chatBubble) ,
                    };
                    content.history.push(newEntry);
                }
                
            }
            return content;
    }

    if(!selectedContent && scope == 'useSelectedForContext'){
        setScope('universal')
    }

    const sendMessage = async (e) => {
        e.stopPropagation();
        const userMessage = input.current.textContent.trim();
        if (!userMessage) return;
        setActiveChat(true);
        setShowFullPromptModule(true)
        input.current.textContent = "";
        const id = uuidv4();

        const getCanvasContent = (currentBubble) => {
            if (!currentBubble) return null;

            const children = currentBubble.canvasChildren
                ?.map(({ id }) => bubbles.find((b) => b.id === id))
                .filter(Boolean)
                .map(getCanvasContent);

            const docBubbs = currentBubble.documentChildren
                ?.map((childId) => {
                    const childBubble = bubbles.find((b) => b.id === childId);
                    return childBubble ? getBubbleName(childBubble) : "Unnamed Bubble";
                })
                .filter(Boolean);

            if (currentBubble.name) {
                docBubbs.push(getBubbleName(currentBubble));
            }

            return {
                text: docBubbs,
                children,
            };
        };

        if (scope === "currentView") {
            const canvasContent = getCanvasContent(canvasbubble);
            console.log(canvasContent)
            addDocumentBubble({
                id: id,
                parentBubbleId: conversationBubble,
                properties: {
                    name: `{"id":"${id}","type":"paragraph","content":[{"type":"text","text":${JSON.stringify(userMessage)}}],"props":{"textColor":"default","backgroundColor":"hu-single","textAlignment":"left"},"children":[]}`,
                },
            });
            console.log(chatMemory(canvasContent))
            handleTextGeneration({
                promptText: userMessage,
                parentId: conversationBubble,
                content: chatMemory(canvasContent),
            });
        } else if (scope === "useSelectedForContext") {
            addDocumentBubble({
                id: id,
                parentBubbleId: conversationBubble,
                properties: {
                    name: `{"id":"${id}","type":"paragraph","content":[{"type":"text","text":${JSON.stringify(userMessage)}}],"props":{"textColor":"default","backgroundColor":"hu-single","textAlignment":"left"},"children":[]}`,
                },
            });
            handleTextGeneration({
                promptText: userMessage,
                parentId: conversationBubble,
                content: chatMemory(selectedContent),
            });
        } else {
            addDocumentBubble({
                id: id,
                parentBubbleId: conversationBubble,
                properties: {
                    name: `{"id":"${id}","type":"paragraph","content":[{"type":"text","text":${JSON.stringify(userMessage)}}],"props":{"textColor":"default","backgroundColor":"hu-single","textAlignment":"left"},"children":[]}`,
                },
            });
            
            handleTextGeneration({
                promptText: userMessage,
                parentId: conversationBubble,
                content: chatMemory()
            });

        }
    };

    useEffect(()=>{
        const delta = 6;
        let startX;
        let startY;
        
        document.getElementById('canvas').addEventListener('mousedown', function (event) {
          startX = event.pageX;
          startY = event.pageY;
        });
        
        document.getElementById('canvas').addEventListener('mouseup', function (event) {
          const diffX = Math.abs(event.pageX - startX);
          const diffY = Math.abs(event.pageY - startY);
          if(isOpen.state){
            setShowFullPromptModule(!(diffX < delta && diffY < delta));
        }
        });
    }, [])


    const resetConversationBubble = (e) => {
        e.stopPropagation();
        const newId = uuidv4();
        addBubble({ id: newId, viewMode: 'chat' });
        removeCookie('conversationBubble', { path: '/' });
        setConversationBubble(newId);
        setCookie('conversationBubble', newId, { path: '/', maxAge: 60 * 60 * 24 });
        setConversationTitle('');
        setActiveChat(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage(e);
        }
    };

    useEffect(() => {
        if (conversationBubble && !conversationTitle) {
            const bubble = bubbles.find((b) => b.id === conversationBubble);
            if (bubble && bubble.name) {
                setConversationTitle(getBubbleName(bubble));
            }
        }
    }, [conversationBubble, bubbles]);

    return (
        <div
            ref={canvasRef}
            className={classnames(
                styles.promptModule,
                "promptModule",
                { [styles.activeChat]: activeChat },
                { [styles.showFull]: showFullPromptModule }
            )}
        >
            <div
                className={styles.header}
                id='chat-canvas'
                onClick={() => setShowFullPromptModule(!showFullPromptModule)}
                draggable={true}             
                >
                <div className={styles.headerTitle}>
                    {conversationTitle || ''}
                </div>
                <div className={styles.headerButtons}>
                    <Button
                        label="Reset chat"
                        size="small"
                        onClick={resetConversationBubble}
                    />
                </div>
            </div>
         
            <ChatDocument bubble={bubble} className={classnames([styles.document, bubble?.documentChildren?.length > 1 ? "" : styles.documentCollapse])} /> 

            <div className={styles.selectScope}>
                <div className={styles.scopes}>
                    <div
                        className={scope === 'universal' ? styles.selected : ''}
                        onClick={(e) => {
                            e.stopPropagation();
                            setScope('universal');
                        }}
                    >
                        ChatGPT
                    </div>
                    <div
                        className={scope === 'currentView' ? styles.selected : ''}
                        onClick={(e) => {
                            e.stopPropagation();
                            setScope('currentView');
                        }}
                    >
                        Current view
                    </div>
                    <div className={styles.useSelected}  onClick={(e) => {
                                e.stopPropagation();
                            }}>
                        <input
                            type="checkbox"
                            id="useSelected"
                            disabled={!selectedContent} 
                            onChange={(e) => {
                                e.stopPropagation();
                                if (e.target.checked && selectedContent) {
                                    setScope('useSelectedForContext'); 
                                } else {
                                    setScope('universal'); 
                                }
                            }}
                            checked={scope === 'useSelectedForContext' && selectedContent} 
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                        <label
                            htmlFor="useSelected"
                            disabled={!selectedContent} 
                            onChange={(e) => {
                                e.stopPropagation();
                                if (e.target.checked && selectedContent) {
                                    setScope('useSelectedForContext'); 
                                } else {
                                    setScope('universal'); 
                                }
                            }}
                            checked={scope === 'useSelectedForContext'} 
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            Use selected for context
                        </label>
                    </div>
                </div>
            </div>

            <div className={styles.inputContainer}>
                <div
                    ref={input}
                    className={classnames([styles.input, 'text-input'])}
                    contentEditable
                    data-placeholder="Ask a question.."
                    onClick={(e) => {e.stopPropagation(); setShowFullPromptModule(true)}}
                    onKeyDown={(e) => handleKeyDown(e)}
                ></div>
                <Button
                    icon={<ArrowUp size={16} />}
                    onClick={(e) => sendMessage(e)}
                />
            </div>
        </div>
    );
};

export default ChatCanvas;
